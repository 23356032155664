.employees {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__left {
    display: flex;
    align-items: center;

    &-blacklist {
      color: rgb(42, 42, 42);
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
      border-bottom: 1px solid rgb(42, 42, 42);
      cursor: pointer;
      margin-right: 18px;
      padding-bottom: 3px;
    }
    &-button--white {
      color: rgb(77, 74, 234);
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
      padding: 8px 26px;
      box-sizing: border-box;
      border: 2px solid rgb(77, 74, 234);
      border-radius: 8px;
      margin-right: 8px;
    }
  }
}

.addEmployee__label {
  margin-bottom: 3px;
  color: rgb(56, 56, 56);
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0%;
}
.blacklist__delete {
  color: rgb(242, 83, 83);
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  padding-bottom: 2px;
  border-bottom: 1px solid rgb(242, 83, 83);
  text-align: center;
  cursor: pointer;
}