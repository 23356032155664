.projects {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }
}
.project {
  &__item {
    box-sizing: border-box;
    border: 2px solid rgb(236, 236, 238);
    border-radius: 12px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
  }
  &__left {
    display: flex;
  }
  &__descr {
    margin-left: 10px;
    padding: 5px 0;
    &-title {
      color: rgb(42, 42, 42);
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      margin-bottom: 16px;
    }
    &-amount {
      padding-left: 5px;
    }
    &-button {
      color: rgb(77, 74, 234);
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0px;
      margin-left: 10px;
      padding: 8px 12px;
      box-sizing: border-box;
      border: 1px solid rgb(236, 236, 238);
      border-radius: 8px;
      background: rgb(249, 249, 253);
    }
  }
  &__right {
    align-self: flex-end;
  }
  &__button {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: rgb(244, 243, 255);
    padding: 5px 12px;
    &-text {
      padding-left: 4px;
      color: rgb(77, 74, 234);
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
    }
  }
}

.addProj {
  &__modal .ant-modal-content {
    background: rgb(249, 249, 253);
  }
  &__title {
    color: rgb(56, 56, 56);
    font-size: 19px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0%;
    margin-bottom: 25px;
    text-align: center;
  }
  &__input {
    margin-bottom: 16px;
  }
  &__text {
    color: rgb(56, 56, 56);
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0%;
  }
  &__button {
    margin-right: 31px;
    padding: 8px 26px;
    border-radius: 8px;
    background: rgba(77, 74, 234, 0.15);
    color: rgb(77, 74, 234);
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
  }
  &__employees {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 16px;
    margin-bottom: 80px;
  }
  &__employee {
    position: relative;
    border-radius: 11px;
    box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.05);
    background: rgb(255, 255, 255);
    margin-bottom: 8px;
    padding: 7px 12px;
    &-close {
      position: absolute;
      top: -25%;
      right: -2%;
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
    &-name {
      color: rgb(56, 56, 56);
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0%;
      margin-bottom: 2px;
    }
    &-job {
      color: rgba(56, 56, 56, 0.5);
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0%;
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    
    &-button {
      padding: 8px 26px;
      border-radius: 8px;
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
      
      &--purple {
        background: rgb(77, 74, 234);
        color: rgb(255, 255, 255);
        margin-right: 12px;
      }
      &--white {
        color: rgb(77, 74, 234);
        box-sizing: border-box;
        border: 1px solid rgb(77, 74, 234);
        border-radius: 8px;
        background: rgb(255, 255, 255);
      }
    }
  }
}

.addEmp {
  &__title {
    text-align: center;
    margin-bottom: 16px;
    color: rgb(42, 42, 42);
    font-size: 17px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
  }
  &__button {
    margin-top: 15px;
  }
}