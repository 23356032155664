.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #fff;
  border-right: 2px solid rgb(236, 236, 238);
  max-width: 260px;
  width: 100%;
  padding: 29px 14px 38px 28px;
  overflow-y: auto;

  &__link {
    display: block;
    margin-bottom: 30px;
    padding-left: 16px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &-nav__list {
    &-link {
      display: flex;
      padding: 12px 0 12px 16px;
      border-radius: 6px;
      transition: all .4s;
      margin: 10px 0;
      &:hover {
        background-color: #eee;
      }
    }
    &-item--logout {
      position: absolute;
      bottom: 0;
      left: 50%;

      .sidebar-nav__list-link {
        padding-right: 40px;
      }
    }
  }
  &-nav__item {
    &-text {
      color: rgb(42, 42, 42);
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      padding-left: 8px;
    }
  }
}
