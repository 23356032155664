.auth {
  background: rgb(249, 249, 253);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__box {
    max-width: 500px;
    padding: 50px 100px;
    border-radius: 12px;
    background: rgb(255, 255, 255);

    &-input--error {
      border: 1px solid rgb(242, 83, 83);
    }
    &-title {
      margin-bottom: 24px;
      text-align: center;
      color: rgb(42, 42, 42);
      font-size: 24px;
      font-weight: 500;
      line-height: 38px;
      letter-spacing: 0px;
    }
    &-label {
      display: block;
      margin-bottom: 5px;
      color: rgb(126, 126, 126);
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0%;
    }
    &-button {
      padding: 8px 26px;
      color: rgb(255, 255, 255);
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
      border-radius: 8px;
      background: rgb(217, 217, 217);
      width: 100%;

      &--colored {
        background: rgb(77, 74, 234);
      }
    }
    &-error {
      color: rgb(242, 83, 83);
    }
  }
}