html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

.df {
  display: flex;
}
.jcc {
	justify-content: center;
}
.jcsb {
  justify-content: space-between;
}
.jcsa {
	justify-content: space-around;
}
.aic {
  align-items: center;
}
.dg {
  display: grid;
}

* {
	box-sizing: border-box;
}
a {
	color: inherit;
	text-decoration: none;
}
button {
	border: none;
	cursor: pointer;
}
body {
	font-family: 'Inter';
}
.wrapper {
	display: flex;
}
.content {
	margin-left: 288px;
	margin-top: 32px;
	padding-right: 60px;
	flex: 1;
}
.title {
	color: rgb(42, 42, 42);
	font-size: 28px;
	font-weight: 500;
	line-height: 38px;
	letter-spacing: 0px;
	margin-bottom: 24px;
}
.button--purple {
	color: rgb(255, 255, 255);
	font-size: 15px;
	font-weight: 700;
	line-height: 18px;
	letter-spacing: 0px;
	padding: 8px 26px;
	border-radius: 8px;
	background: rgb(77, 74, 234);
}
.ant-table-cell {
	color: rgba(42, 42, 42, 0.5);
	font-size: 15px;
	font-weight: 500;
	line-height: 140%;
	letter-spacing: 0px;
}
.ant-collapse-header-text {
	font-size: 17px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: 0px;
}
.none {
	display: none;
}