.requests {
  &__header {
    margin-bottom: 13px;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgb(236, 236, 238);
  }
  &__left {
    display: flex;
    column-gap: 33px;
  }
  &__left-tab {
    color: rgba(42, 42, 42, 0.5);
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    cursor: pointer;
    &--active {
      color: rgb(77, 74, 234);
      border-bottom: 3px solid rgb(77, 74, 234);
      padding: 0 3px 25px;
    }
  }
  &__tab-text {
    display: inline;
  }
  &__tab-badge {
    padding: 6px;
    border-radius: 100px;
    background: rgb(249, 249, 253);
    color: rgb(42, 42, 42);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0%;
  }
  &__right {
    padding-bottom: 15px;
  }
}

.requests {
  &__table {
    padding: 15px 19px;
    &-req {
      color: rgb(77, 74, 234);
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      cursor: pointer;
      & img {
        margin-left: 5px;
      }
    }
    &-members {
      display: inline-block;
      box-sizing: border-box;
      border: 1px solid rgb(236, 236, 238);
      border-radius: 8px;
      padding: 2px 4px;
      background: rgb(249, 249, 253);
      & span {
        margin-left: 5px;
      }
    }
    &-urgency {
      padding: 8px 12px;
      border-radius: 8px;
      background: rgb(234, 253, 238);
    }
    &-date {
      display: flex;
      margin-bottom: 5px;
    }
    &-date span {
      display: block;
      font-size: 14px;
      margin-left: 5px;
    }
    &-dialog {
      padding: 4px 12px;
      border-radius: 8px;
      background: rgb(244, 243, 255);
    }
  }
}

.newTickets__box {
  padding: 26px 0 53px;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(236, 236, 238);
  border-left: 2px solid rgb(236, 236, 238);
  border-right: 2px solid rgb(236, 236, 238);
  border-top: 2px solid rgb(236, 236, 238);
  border-radius: 12px;
  background: rgb(249, 249, 253);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &--content {
    max-width: 740px;
  }
  &-title {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    color: rgb(42, 42, 42);
    font-size: 22px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0px;
  }
}
.newTickets__form {
  &-label {
    display: block;
    margin-bottom: 8px;
    color: rgba(42, 42, 42, 0.5);
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
  }
  &-item {
    margin-bottom: 24px;
    margin-right: 10px;
  }
  &-select {
    width: 270px;
  }
  &-item--textarea {
    margin-bottom: 60px;
  }
  &-textarea {
    width: 100%;
    resize: none;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 8px;
    background: rgb(255, 255, 255);
  }
  &-footer {
    display: flex;
    justify-content: center;
    &--left {
      margin-right: 12px;
    }
    &--right {
      color: rgb(77, 74, 234);
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
      padding: 8px 26px;
      border-radius: 8px;
      background: rgb(255, 255, 255);
    }
  }
}
