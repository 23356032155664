.conv {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      display: flex;
      align-items: center;
    }
    &-title {
      margin-bottom: 0;
    }
    &-back {
      display: inline;
      margin-right: 25px;
      cursor: pointer;
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-button--white {
      color: rgb(77, 74, 234);
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
      padding: 8px 12px;
      box-sizing: border-box;
      border: 1px solid rgb(77, 74, 234);
      border-radius: 8px;
      background-color: #fff;
      display: flex;
      align-items: center;
      margin-right: 12px;
      & img {
        margin-right: 5px;
      }
    }
    &-button--purple {
      margin-right: 12px;
    }
    &-delete {
      cursor: pointer;
    }
  }
  &__main {
    max-width: 1200px;
    width: 100%;
  }
  &__project {
    margin-top: 23px;
    margin-bottom: 8px;
    padding: 12px 0 12px 16px;
    box-sizing: border-box;
    border: 2px solid rgb(236, 236, 238);
    border-radius: 12px;
    background: rgb(249, 249, 253);

    &-title {
      color: rgb(42, 42, 42);
      font-size: 17px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
      margin-bottom: 8px;
    }
    &-descr {
      color: rgba(42, 42, 42, 0.61);
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
    }
  }
  &__req {
    margin-bottom: 8px;
  }
  &__date {
    text-align: center;
    margin-bottom: 8px;
    color: rgba(42, 42, 42, 0.5);
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
  }
  &__message-header {
    box-sizing: border-box;
    border: 2px solid rgb(236, 236, 238);
    border-radius: 12px;
    background: rgb(250, 250, 250);
  }
  &__message {
    margin-bottom: 7%;

    &-name {
      margin-right: 4px;
      color: rgb(42, 42, 42);
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
      
      span {
        color: rgba(42, 42, 42, 0.5);
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        margin-right: 10px;
      }
    }
    &-time {
      color: rgba(42, 42, 42, 0.5);
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
    }
    &-content {
      color: rgb(42, 42, 42);
      font-size: 15px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0px;
    }
    &-right {
      display: flex;
      align-items: center;

      & img {
        cursor: pointer;
      }
    }
    &-replies {
      background-color: white;

      & span {
        color: rgba(42, 42, 42, 0.5);
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
      }
    }
  }
  &__urgency {
    color: rgba(42, 42, 42, 0.5);
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    margin: 30px 0;

    &-name {
      color: rgb(42, 42, 42);
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
    }
    &-status {
      display: inline;
      padding: 8px 12px;
      border-radius: 8px;
      margin: 0 8px;
    }
    &-time {
      margin-left: 15px;
    }
  }
}

.reply__message {

  &-textarea {
    border-radius: 8px;
    background: rgb(255, 255, 255);
    padding: 10px 8px;
    width: 100%;
    resize: none;
  }
  &-icon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0.1%;
  }
}
.reply__message-footer {
  position: relative;
}

.conv__footer {
  bottom: 0;
  padding: 14px 29px 19px 29px;

  &-textarea {
    border-radius: 8px;
    background: rgb(255, 255, 255);
    padding: 10px 8px;
    width: 100%;
    resize: none;
    width: 100%;
  }
  &-b {
    position: relative;
  }
  &-send {
    cursor: pointer;
    position: absolute;
    right: 1%;
    top: 10%;
  }
  &-button {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    color: rgb(77, 74, 234);
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0);
    cursor: pointer;
  }
}

.conv__aside {
  border-left: 2px solid rgb(236, 236, 238);
  background: rgb(255, 255, 255);
  padding-left: 27px;
  margin-left: 26px;
  max-width: 170px;

  &-title {
    color: rgb(42, 42, 42);
    font-size: 22px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0px;
    margin-bottom: 21px;
  }
  &-label {
    display: block;
    margin-bottom: 5px;
    color: rgba(42, 42, 42, 0.5);
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
  }
  &-select {
    width: 120%;
    margin-bottom: 12px;
  }
  &-date {
    width: 120%;
    margin-bottom: 12px;
  }
  &-executor {
    padding: 11px 17px;
    box-sizing: border-box;
    border: 2px solid rgb(236, 236, 238);
    border-radius: 12px;
    position: relative;
    width: 120%;
    margin-bottom: 12px;
  }
}
.conv__executor {
  &-name {
    color: rgb(42, 42, 42);
    font-size: 15px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0px;
    margin-bottom: 4px;
  }
  &-job {
    color: rgba(42, 42, 42, 0.5);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
  }
  &-img {
    cursor: pointer;
    position: absolute;
    top: 20%;
    right: 5%;
  }
}

.relatedTickets {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 2px solid rgb(236, 236, 238);

  &__img {
    width: 15px;
  }
  &__text {
    margin-left: 10px;
    color: rgb(77, 74, 234);
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
  }
}

.conv__related {
  &-title {
    margin-bottom: 16px;
    color: rgb(42, 42, 42);
    font-size: 17px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
  }
  &-select {
    width: 100%;
    margin-bottom: 16px;
  }
  &-button {
    margin: 0 auto;
  }
}